<template>
  <div class="config-page" v-loading="loading">
    <el-form v-if="config" label-width="95px" size="medium" :model="config" :rules="rules" ref="config">
      <div class="custom-form">
        <div class="form-content">
          <p>奖励设置：</p>
          <el-form-item label-width="0px" prop="min_reward">
            <el-input type="number" v-model.number="config.min_reward" placeholder="奖励最小值">
              <span slot="suffix">积分</span>
            </el-input>
          </el-form-item>
          <div style="margin: 0 10px">~</div>
          <el-form-item label-width="0px" prop="max_reward">
            <el-input type="number" v-model.number="config.max_reward" placeholder="奖励最大值">
              <span slot="suffix">积分</span>
            </el-input>
          </el-form-item>
        </div>
        <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>奖励最小值与最大值，如果值相同时奖励积分固定</span>
        </div>
      </div>
      <el-form-item label="排名奖励：" prop="daily_rank_rewards">
        <div class="form-tip" style="margin-top: 0px;line-height:36px">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>前N名签到有积分奖励，如前5名奖励10积分，前10名（6-10）奖励5积分</span>
        </div>
        <el-table :data="config.daily_rank_rewards" style="width: 600px;" border>
          <el-table-column show-overflow-tooltip v-for="th in thead.daily_rank_rewards" :key="th.prop"
            :label="th.label" :prop="th.prop" :width="th.width|| ''" :min-width="th.minWidth || ''"
            :fixed="th.fixed">
            <template slot-scope="{row,$index}">
              <!-- 操作 -->
              <template v-if="th.prop==='operate'">
                <!-- <el-button size="small" type="text" @click="onAddEdit('edit',row)">编辑</el-button> -->
                <el-button size="small" type="text" @click="onDelete($index,0)">删除</el-button>
              </template>
              <el-input v-else type="number" v-model.number="row[th.prop]" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="add-btn" size="small" @click="handleAdd(0)">添加</el-button>
      </el-form-item>
      <el-form-item label="额外奖励：" prop="other_rewards">
        <div class="form-tip" style="margin-top: 0px;line-height:36px">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>本月累计签到多少天，可获得额外积分奖励，每月1号零点重置上月累积签到进度及领取记录</span>
        </div>
        <el-table :data="config.other_rewards" style="width: 600px;" border>
          <el-table-column show-overflow-tooltip v-for="th in thead.other_rewards" :key="th.prop"
            :label="th.label" :prop="th.prop" :width="th.width|| ''" :min-width="th.minWidth || ''"
            :fixed="th.fixed">
            <template slot-scope="{row}">
              <!-- 操作 -->
              <template v-if="th.prop==='operate'">
                <!-- <el-button size="small" type="text" @click="onAddEdit('edit',row)">编辑</el-button> -->
                <el-button size="small" type="text" @click="onDelete($index,1)">删除</el-button>
              </template>
              <el-input v-else type="number" v-model.number="row[th.prop]" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="add-btn" size="small" @click="handleAdd(1)">添加</el-button>
      </el-form-item>
      <el-form-item label="补签所需积分：" prop="makeup_integral">
        <el-input style="width: 260px" type="text" v-model.number="config.makeup_integral"
          placeholder="请输入补签所需积分"></el-input>
        <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>为空则补签不需要积分</span>
        </div>
      </el-form-item>
      <el-form-item label="签到规则：" prop="tips">
        <el-input style="width: 500px" :rows="6" type="textarea" v-model="config.tips" placeholder="请输入签到规则">
        </el-input>
        <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>例如：每日签到即可获得相应的积分，每个月额外签到可获得额外积分奖励。</span>
        </div>
      </el-form-item>
      <el-form-item label="分享标题：" prop="share_title">
        <el-input style="width: 260px" type="text" v-model="config.share_title" placeholder="请输入分享标题">
        </el-input>
      </el-form-item>
      <el-form-item label="分享图片：" prop="share_image">
        <SingleMediaWall v-model="config.share_image" fit="cover" :width="104" :height="104" ratio="5:4"
          :borderRadius="6">
          <p slot="info" class="img-tip">
            <span class="el-icon-info"></span>建议上传尺寸比例为500*400
          </p>
        </SingleMediaWall>
        <el-input v-model="config.share_image" v-show="false"></el-input>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig('config')" :loading="saveLoading">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { SignConfig, saveSignConfig } from "../../api/sign-config";
import SingleMediaWall from "../../../common/components/SingleMediaWall";
import FixedActionBar from "@/base/layout/FixedActionBar";
export default {
  components: { SingleMediaWall, FixedActionBar },
  data() {
    return {
      loading: false,
      config: null,
      thead: {
        daily_rank_rewards: [
          { label: "排名", prop: "rank", minWidth: 100 },
          { label: "奖励", prop: "reward", minWidth: 100 },
          { label: "操作", prop: "operate", minWidth: 50 },
        ],
        other_rewards: [
          { label: "累计签到", prop: "accumulate_days", minWidth: 100 },
          { label: "奖励", prop: "reward", minWidth: 100 },
          { label: "操作", prop: "operate", minWidth: 50 },
        ],
      },
      rules: {
        min_reward: [
          { required: true, message: "请输入最小值", trigger: "blur" },
        ],
        max_reward: [
          {
            required: true,
            message: "请输入最大值",
            trigger: "blur",
          },
        ],
      },
      saveLoading: false,
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 获取配置详情
    getDetail() {
      this.loading = true;
      SignConfig()
        .then((res) => {
          this.config = res.data;
          console.log(res.data);
          this.loading = false;
          this.saveLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.saveLoading = false;
        });
    },
    // 点击添加
    handleAdd(i) {
      if (i) {
        this.config.other_rewards.push({
          accumulate_days: "",
          reward: "",
          timestraps: new Date().getTime(),
        });
      } else {
        this.config.daily_rank_rewards.push({
          rank: "",
          reward: "",
          timestraps: new Date().getTime(),
        });
      }
    },
    // 点击删除
    onDelete(index, i) {
      if (i) {
        this.config.other_rewards.splice(index, 1);
      } else {
        this.config.daily_rank_rewards.splice(index, 1);
      }
    },
    saveConfig(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          this.loading = true;
          saveSignConfig({ config: this.config })
            .then((res) => {
              this.$message.success(res.msg);
              this.getDetail();
            })
            .catch((err) => {
              this.saveLoading = false;
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.config-page {
  min-height: 500px;
}
.custom-form {
  margin-bottom: 22px;
  .form-tip {
    margin-left: 95px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #606266;
    line-height: 12px;
    margin-top: 15px;
    span + span {
      margin-left: 4px;
    }
  }
  .form-content {
    display: flex;
    align-items: center;
    .el-form-item {
      margin-bottom: 0;
    }
    p {
      padding-right: 12px;
      width: 95px;
      text-align: end;
      font-size: 14px;
      white-space: nowrap;
      color: #606266;
      &::before {
        content: "*";
        color: red;
        margin-right: 4px;
      }
    }
    ::v-deep.el-input__suffix {
      right: 10px;
    }
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
.img-tip {
  font-size: 14px;
  color: #b2b2b2;
  margin-top: 10px;
}
.el-table {
  ::v-deepth {
    line-height: 12px;
  }
  ::v-deep.el-table__empty-block {
    border-bottom: 1px #ebeef5 solid;
  }
}
.add-btn {
  margin-top: 10px;
  width: 600px;
  border: 1px dashed #909399;
  &:hover {
    border-color: #3576ff;
    background-color: #fff;
  }
}
</style>
