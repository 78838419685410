import api from "@/base/utils/request";

// 获取签到配置详情
export const SignConfig = data => {
  return api({
    url: "/admin/incomes/user_sign/getUserSignConfig",
    method: "post",
    data
  });
};

// 保存签到配置
export const saveSignConfig = data => {
  return api({
    url: "/admin/incomes/user_sign/setUserSignConfig",
    method: "post",
    data
  });
};